import { User } from 'firebase/auth';
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
} from 'react';
import { useNavigate } from 'react-router';
import { ToastPortal, ToastType } from '../../components/Toast/ToastPortal';
import { defaultSwapkaartValues } from '../../constants/swapkaartData';
import { PreOrderedCardType } from '../../Types/PreOrderedCard';
import { SwapkaartType } from '../../Types/Swapkaart';
import {
    deleteDocumentWithWhere,
    getDocumentID,
    getDocumentsWithWhere,
    getDocumentsWithWhereAndOrder,
    updateSingleDocumentWithDocID,
    writeSingleDocument,
    writeSingleDocumentWithDocID,
} from '../../util/Firestore';
import { deleteFolder } from '../../util/Storage';
import { useGlobalLoadingState } from '../GlobalLoadingContext';
import { useAuth } from '../UserContext';
import { cardsReducer } from './cardsReducer';

type ContextType = {
    cards: SwapkaartType[] | null;
    teamCards: SwapkaartType[] | null;
    loading: boolean;
    updateData: (payload: SwapkaartType, docId: string) => Promise<void>;
    handleCreateCard: ({
        isFirst,
        currPayload,
    }: {
        isFirst?: boolean;
        currPayload?: {
            productID: string;
            productName?:
                | 'Swapkaart Original'
                | 'Swapkaart Classic'
                | 'Swapkaart Premium';
        };
    }) => void;
    handleCardDelete: (docId: string, isTeamCard?: boolean) => void;
    checkIfUsernameExists: (username: string) => Promise<boolean>;
    getSingleCard: (id: string) => Promise<any>;
    handleSetDefault: (card: SwapkaartType, docId: string) => Promise<void>;
    activatePreorderedCard: ({
        isFirst,
        currPayload,
        user,
    }: {
        isFirst?: boolean | undefined;
        currPayload: PreOrderedCardType;
        user: User;
    }) => Promise<void>;
    getPreOrderedCards: (id: string) => Promise<PreOrderedCardType | undefined>;
    getAllUserCards: (userId: string) => Promise<void>;
    getAllTeamCards: (teamOwnerId: string) => Promise<void>;
    sendEmailInfo: (
        data: {
            email: string;
            phone: string;
            name: string;
            notes: string;
            company: string;
            cardId: string;
            ownerID: string;
            teamOwnerId?: string;
            cardEmail: string;
            manualAdd?: boolean;
        },
        cardEmail: string,
        tooltipText?: string
    ) => Promise<string>;
};

export const MyCardsContext = createContext({} as ContextType);
export const useMyCards = () => useContext(MyCardsContext);

const MyCardsProvider = ({ children }: { children: ReactNode }) => {
    const { setGlobalLoading } = useGlobalLoadingState();
    const navigate = useNavigate();
    const toastRef = useRef<ToastType | null>(null);
    const { user, teamOwnerId, subscription } = useAuth();
    const [state, dispatch] = useReducer(cardsReducer, {
        cards: null,
        teamCards: null,
        loading: true,
    });

    const getPreOrderedCards = async (id: string) => {
        dispatch({ type: 'LOADING_CARDS', payload: true });
        try {
            // Try to get the card by document ID
            const currCard: PreOrderedCardType[] | undefined =
                await getDocumentsWithWhere(
                    'pre-ordered-cards',
                    [{ field: 'docId', operator: '==', searchValue: id }],
                    1,
                    false
                );
            if (currCard && currCard.length) {
                dispatch({ type: 'LOADING_CARDS', payload: false });
                return currCard[0];
                // If not exists, try get it by the username
            }
        } catch (e: any) {
            dispatch({ type: 'LOADING_CARDS', payload: false });
            throw new Error(e.message);
        }
    };

    const activatePreorderedCard = async ({
        isFirst,
        currPayload,
        user,
    }: {
        isFirst?: boolean;
        currPayload: {
            docId: string;
            activated: boolean;
            productID: string;
            productName:
                | 'Swapkaart Original'
                | 'Swapkaart Classic'
                | 'Swapkaart Premium';
        };
        user: User;
    }) => {
        dispatch({ type: 'LOADING_CARDS', payload: true });
        try {
            if (user) {
                const payload = {
                    ...defaultSwapkaartValues,
                    productID: currPayload.productID,
                    productName: currPayload.productName,
                };
                payload['ownerId'] = user.uid;
                payload['name'] = user?.displayName ?? '';
                payload['username'] = currPayload.docId;
                payload['docId'] = currPayload.docId;

                if (isFirst === true) payload['default'] = true;
                else payload['default'] = false;
                const result = await Promise.allSettled([
                    writeSingleDocumentWithDocID(
                        'swapkaart',
                        {
                            ...payload,
                        },
                        currPayload.docId
                    ),
                    updateSingleDocumentWithDocID(
                        'pre-ordered-cards',
                        {
                            ...currPayload,
                            activated: true,
                        },
                        currPayload.docId
                    ),
                ]);
                if (result) {
                    toastRef.current?.addToast({
                        message: 'Card successfully activated!',
                        position: 'top-right',
                        type: 'confirm',
                    });

                    dispatch({
                        type: 'ADD_TO_CARDS',
                        payload,
                    });
                    setGlobalLoading(false);
                    navigate('/customize/edit/' + payload.docId);
                }
            }
        } catch (e) {
            dispatch({ type: 'LOADING_CARDS', payload: false });
            setGlobalLoading(false);
            console.log(e);
            throw new Error(String(e));
        }
    };

    const getSingleCard = useCallback(async (id: string) => {
        dispatch({ type: 'LOADING_CARDS', payload: true });
        try {
            // Try to get the card by document ID
            const currCard = await getDocumentsWithWhere(
                'swapkaart',
                [{ field: 'docId', operator: '==', searchValue: id }],
                1,
                false
            );
            if (currCard && currCard.length) {
                dispatch({ type: 'LOADING_CARDS', payload: false });
                return currCard[0];
                // If not exists, try get it by the username
            }
        } catch (e: any) {
            dispatch({ type: 'LOADING_CARDS', payload: false });
            throw new Error(e.message);
        }
    }, []);

    const sendEmailInfo = async (
        data: {
            email: string;
            phone: string;
            name: string;
            notes: string;
            company: string;
            cardId: string;
            ownerID: string;
            teamOwnerId?: string;
            cardEmail: string;
            manualAdd?: boolean;
        },
        cardEmail: string,
        tooltipText?: string
    ) => {
        try {
            if (!data.manualAdd) {
                data['manualAdd'] = false;
            }

            if (subscription !== 'team') {
                delete data.teamOwnerId;
            }

            const res = await writeSingleDocument('connection', data);

            // await sendEmailWithTemplate('send-email-form', data, cardEmail);
            if (res) {
                toastRef.current?.addToast({
                    message: tooltipText
                        ? tooltipText
                        : `Thank you for submitting the form!`,
                    type: 'confirm',
                    position: 'top-right',
                });
            }
            return res;
        } catch (e: any) {
            throw new Error(e);
        }
    };

    //update setDefaultValue on card
    const handleSetDefault = async (card: SwapkaartType, docId: string) => {
        dispatch({ type: 'LOADING_CARDS', payload: true });
        try {
            if (state.cards) {
                const currDefaultCard = state.cards.find(
                    (card) => card.default === true
                );
                if (currDefaultCard) {
                    currDefaultCard['default'] = false;
                    const payload = card;
                    payload['default'] = true;
                    const res = await Promise.allSettled([
                        await updateSingleDocumentWithDocID(
                            'swapkaart',
                            payload,
                            docId
                        ),
                        await updateSingleDocumentWithDocID(
                            'swapkaart',
                            currDefaultCard,
                            currDefaultCard.docId!
                        ),
                    ]);
                    if (res) {
                        dispatch({
                            type: 'SET_DEFAULT_CARD',
                            payload: {
                                currDefaultCard,
                                updatedDefaultCard: payload,
                            },
                        });
                        toastRef.current?.addToast({
                            message: `Card ${card.title} is set to default`,
                            type: 'confirm',
                            position: 'top-right',
                        });
                    }
                } else {
                    const payload = card;
                    payload['default'] = true;
                    const res = await updateSingleDocumentWithDocID(
                        'swapkaart',
                        payload,
                        docId
                    );
                    if (res) {
                        dispatch({ type: 'UPDATE_CARD_DATA', payload });
                        toastRef.current?.addToast({
                            message: `Card ${card.title} is set to default`,
                            type: 'confirm',
                            position: 'top-right',
                        });
                    }
                }
            }
        } catch (e: any) {
            dispatch({ type: 'LOADING_CARDS', payload: false });
            throw new Error(e.message);
        }
    };

    // update cards

    const updateData = async (payload: SwapkaartType) => {
        dispatch({ type: 'LOADING_CARDS', payload: true });
        try {
            payload['userNameChanged'] = true;
            // temporary solution. Removing the VCF fields.
            // at the time of writing the VCF values are passed with old data.
            // When the state of  the card changes, the vcf field values are not updated.
            delete payload.vcfFileName;
            delete payload.vcfLink;
            const result = await updateSingleDocumentWithDocID(
                'swapkaart',
                payload,
                payload.docId!
            );
            if (result === 'success') {
                dispatch({ type: 'UPDATE_CARD_DATA', payload });
                toastRef.current?.addToast({
                    message: 'Changes saved!',
                    type: 'confirm',
                    position: 'top-right',
                });

                // if (width && width > 1025) {
                //     navigate(`/customize/edit/${payload.docId}`);
                // }
            }
        } catch (e: any) {
            dispatch({ type: 'LOADING_CARDS', payload: false });
            throw new Error(e);
        }
    };

    //check if username exists
    const checkIfUsernameExists = async (username: string) => {
        const currUserName = await getDocumentsWithWhere(
            'swapkaart',
            [{ field: 'username', operator: '==', searchValue: username }],
            1,
            false
        );
        if (currUserName && currUserName.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    //delete card and update state
    const handleCardDelete = useCallback(
        async (docId: string, isTeamCard?: boolean) => {
            dispatch({ type: 'LOADING_CARDS', payload: true });

            try {
                if (state.cards || state.teamCards) {
                    // Filter the card to delete
                    const allCards = [
                        ...(state.cards || []),
                        ...(state.teamCards || []),
                    ];
                    const card = allCards.filter(
                        (item) =>
                            item.username === docId || item.docId === docId
                    )[0];

                    // if found delete the document using the doc ID
                    if (card && card.docId) {
                        const result = await deleteDocumentWithWhere(
                            'swapkaart',
                            [card.docId]
                        );
                        if (result === 'success') {
                            await Promise.allSettled([
                                deleteFolder('images', card.docId),
                                deleteFolder('files', card.docId),
                                deleteFolder('appleWallets', card.docId),
                                deleteFolder('vcf-files', card.docId),
                            ]);

                            if (isTeamCard) {
                                dispatch({
                                    type: 'HANDLE_TEAMS_CARD_DELETE',
                                    payload: card?.docId ?? '',
                                });

                                navigate('/customize/teams-cards', {
                                    replace: true,
                                });
                            } else {
                                dispatch({
                                    type: 'HANDLE_CARD_DELETE',
                                    payload: card?.docId ?? '',
                                });

                                navigate('/customize', { replace: true });
                            }

                            toastRef.current?.addToast({
                                message: `Card deleted!`,
                                type: 'alert',
                                position: 'top-right',
                            });

                            dispatch({ type: 'LOADING_CARDS', payload: false });
                        }
                    }
                }
            } catch (e: any) {
                dispatch({ type: 'LOADING_CARDS', payload: false });
                throw new Error(e.message);
            }
        },
        [navigate, state.cards, state.teamCards]
    );

    const handleCreateCard = async ({
        isFirst,
        currPayload,
    }: {
        isFirst?: boolean;
        currPayload?: {
            productID: string;
            productName?:
                | 'Swapkaart Original'
                | 'Swapkaart Classic'
                | 'Swapkaart Premium';
        };
    }) => {
        dispatch({ type: 'LOADING_CARDS', payload: true });
        try {
            if (user) {
                const payload = { ...defaultSwapkaartValues, ...currPayload };
                payload['ownerId'] = user.uid;
                payload['name'] = user?.displayName ?? '';
                const documentID = await getDocumentID('swapkaart');
                //! Bellow is when we want to create with manual document IDS
                // const documentID = '602591';
                // payload['productID'] = 'Swapkaart Premium';
                // payload['productName'] = 'Swapkaart Premium';
                // !TILL HERE
                payload['username'] = documentID;
                payload['docId'] = documentID;

                if (isFirst === true) payload['default'] = true;
                else payload['default'] = false;

                if (teamOwnerId) payload['teamOwnerId'] = teamOwnerId;

                const result = await writeSingleDocumentWithDocID(
                    'swapkaart',
                    {
                        ...payload,
                    },
                    documentID
                );

                if (result) {
                    payload['docId'] = result;

                    dispatch({
                        type: 'ADD_TO_CARDS',
                        payload,
                    });
                    toastRef.current?.addToast({
                        message: 'Card created!',
                        position: 'top-right',
                        type: 'confirm',
                    });
                    navigate('/customize/edit/' + result);
                }
            }
        } catch (e) {
            dispatch({ type: 'LOADING_CARDS', payload: false });
            console.log(e);
            throw new Error(String(e));
        }
    };

    const getAllUserCards = async (userId: string) => {
        try {
            const result: SwapkaartType[] | undefined =
                await getDocumentsWithWhereAndOrder(
                    'swapkaart',
                    [
                        {
                            field: 'ownerId',
                            operator: '==',
                            searchValue: userId,
                        },
                    ],
                    1000,
                    'timestamp',
                    'desc',
                    false
                );
            if (result) {
                dispatch({ type: 'SET_ALL_CARDS', payload: result });
            } else {
                return;
            }
        } catch (e) {
            console.log(e);
            dispatch({ type: 'LOADING_CARDS', payload: false });
        }
    };

    const getAllTeamCards = async (teamOwnerId: string) => {
        try {
            const result: SwapkaartType[] | undefined =
                await getDocumentsWithWhereAndOrder(
                    'swapkaart',
                    [
                        {
                            field: 'teamOwnerId',
                            operator: '==',
                            searchValue: teamOwnerId,
                        },
                    ],
                    1000,
                    'timestamp',
                    'desc',
                    false
                );

            if (result) {
                // filter cards without the main owner
                const filteredOnlyTeamCards = result.filter(
                    (card) => card.ownerId !== user?.uid
                );
                dispatch({
                    type: 'SET_ALL_TEAM_CARDS',
                    payload: filteredOnlyTeamCards,
                });
            } else {
                return;
            }
        } catch (error) {
            console.log('Error fetching teams cards:', error);
            dispatch({ type: 'LOADING_CARDS', payload: false });
        }
    };

    //fetch all user cards
    useEffect(() => {
        (async () => {
            if (user) {
                await getAllUserCards(user.uid);
            }
        })();
    }, [user]);

    //fetch all teams cards
    useEffect(() => {
        (async () => {
            if (user && teamOwnerId && subscription === 'team') {
                await getAllTeamCards(teamOwnerId);
            }
        })();
    }, [user, teamOwnerId, subscription]);

    const value = {
        loading: state.loading,
        cards: state.cards,
        teamCards: state.teamCards,
        updateData,
        handleCreateCard,
        handleCardDelete,
        checkIfUsernameExists,
        getSingleCard,
        handleSetDefault,
        sendEmailInfo,
        getPreOrderedCards,
        activatePreorderedCard,
        getAllUserCards,
        getAllTeamCards,
    };
    return (
        <MyCardsContext.Provider value={value}>
            <ToastPortal
                position="top-right"
                ref={toastRef}
                autoClose
                autoCloseTimeout={5000}
            />
            {children}
        </MyCardsContext.Provider>
    );
};

export default MyCardsProvider;
