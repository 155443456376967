import { Suspense, useEffect, lazy } from 'react';
import { hotjar } from 'react-hotjar';
import { Route, Routes } from 'react-router-dom';
import { useRolesState } from './context/RolesContext';
import useCustomClaim from './hooks/useCustomClaim';
import { useAuth } from './context/UserContext';
import SuspenseSpinner from './components/Loaders/SuspenseSpinner/SuspenseSpinner';
import { UserLimitationContextProvider } from './context/UserLimitationContext';
import Integrations from './pages/Settings/Integrations';

const CustomFormContactDetails = lazy(() =>
    import('./components/CustomForm/CustomFormContactDetails').then(
        (module) => ({ default: module.CustomFormContactDetails })
    )
);
const CustomFormLinks = lazy(() =>
    import('./components/CustomForm/CustomFormLinks').then((module) => ({
        default: module.CustomFormLinks,
    }))
);
const DetailsNavbar = lazy(() => import('./components/Dashbord/DetailsNavbar'));
const PagesProtectedRoute = lazy(
    () => import('./components/ProtectedRoutes/PagesProtectedRoute')
);

const EditLayout = lazy(() => import('./layout/EditLayout'));
const Contacts = lazy(() => import('./pages/Contacts'));
const CustomizeAppleWallet = lazy(
    () => import('./pages/Customize/CustomizeAppleWallet')
);
const CustomizeDesign = lazy(() => import('./pages/Customize/CustomizeDesign'));
const CustomizeLandingPage = lazy(
    () => import('./pages/Customize/CustomizeLandingPage')
);
const MyCardsCustomize = lazy(() =>
    import('./pages/Customize/MyCardsCustomize').then((module) => ({
        default: module.MyCardsCustomize,
    }))
);
const Login = lazy(() => import('./pages/Login'));
const ZapierOauth = lazy(() => import('./pages/ZapierOauth/ZapierOauth'));
const MyCards = lazy(() => import('./pages/MyCards'));
const Register = lazy(() => import('./pages/Register'));
const Settings = lazy(() =>
    import('./pages/Settings').then((module) => ({ default: module.Settings }))
);
const Plan = lazy(() => import('./pages/Settings/Plan'));
const Roles = lazy(() => import('./pages/Settings/Roles'));
const Teams = lazy(() => import('./pages/Settings/Teams'));
const Purchases = lazy(() => import('./pages/Purchases'));
const DigitalCardsLayout = lazy(() => import('./layout/DigitalCardsLayout'));
const TeamsCards = lazy(() => import('./components/TeamsCards/TeamsCards'));

const HomeLayout = lazy(() => import('./layout/HomeLayout'));
const CustomizeLayout = lazy(() => import('./layout/CustomizeLayout'));
const WidthObserver = lazy(() => import('./context/WidthContext'));
const SettingsLayout = lazy(() => import('./layout/SettingsLayout'));
const Page404 = lazy(() => import('./pages/Page404'));
const DetailsLandingPage = lazy(
    () => import('./pages/Details/DetailsLandingPage')
);
const CustomDesignForm = lazy(() => import('./pages/CustomDesignForm'));
const OnboardingLayout = lazy(() => import('./layout/OnboardingLayout'));
const Onboarding = lazy(() => import('./pages/Onboarding/Onboarding'));
const Subscription = lazy(() => import('./pages/Subscription'));
const LoginRegisterProtectedRoute = lazy(
    () => import('./components/ProtectedRoutes/LoginRegister')
);

declare global {
    interface Window {
        satismeter: any;
    }
}

function App() {
    const { isOrganizationUser, role, user } = useAuth();
    const { userRole } = useCustomClaim();
    const { adminRows, employeeRows } = useRolesState();
    const isCardsAdmin = adminRows[1].checked;
    const isCardsEmployee = employeeRows[1].checked;
    // Identify the user
    useEffect(() => {
        hotjar.initialize(3039161, 3039161);
    }, []);

    useEffect(() => {
        if (user) {
            try {
                (function () {
                    window.satismeter =
                        window.satismeter ||
                        function () {
                            (window.satismeter.q =
                                window.satismeter.q || []).push(arguments);
                        };
                    window.satismeter.l = 1 * (new Date() as any);
                    var script = document.createElement('script');
                    var parent =
                        document.getElementsByTagName('script')[0].parentNode;
                    script.async = 1 as any;
                    script.src = 'https://app.satismeter.com/satismeter.js';
                    parent!.appendChild(script);
                })();

                window.satismeter({
                    writeKey: 'G74BTkNveF9Hn562hwajAOQM4FBXjLzG',
                    userId: user.uid,
                    traits: {
                        name: user.displayName,
                        email: user.email,
                    },
                });
            } catch (err) {
                console.log(err);
            }
        }
    }, [user]);

    return (
        <Suspense fallback={<SuspenseSpinner />}>
            {/* <AnimatePresence exitBeforeEnter> */}
            <UserLimitationContextProvider>
                <Routes>
                    <Route element={<PagesProtectedRoute />}>
                        <Route
                            element={
                                <Suspense fallback={<SuspenseSpinner />}>
                                    <Suspense fallback={<SuspenseSpinner />}>
                                        <HomeLayout />
                                    </Suspense>
                                </Suspense>
                            }
                        >
                            {/* homepage */}

                            {userRole === 'team' &&
                            !(
                                (role === 'admin' && !isCardsAdmin) ||
                                (role === 'employee' && !isCardsEmployee)
                            ) ? (
                                <Route
                                    element={
                                        <Suspense
                                            fallback={<SuspenseSpinner />}
                                        >
                                            <DigitalCardsLayout />
                                        </Suspense>
                                    }
                                >
                                    <Route
                                        index
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <MyCards />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="teams-cards"
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <TeamsCards />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                            ) : (
                                <Route
                                    element={
                                        <Suspense
                                            fallback={<SuspenseSpinner />}
                                        >
                                            <DigitalCardsLayout />
                                        </Suspense>
                                    }
                                >
                                    <Route
                                        index
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <MyCards />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                            )}

                            {/* customize page */}
                            <Route
                                path="customize"
                                element={
                                    <Suspense fallback={<SuspenseSpinner />}>
                                        <CustomizeLayout />
                                    </Suspense>
                                }
                            >
                                <Route
                                    index
                                    element={
                                        <Suspense
                                            fallback={<SuspenseSpinner />}
                                        >
                                            <MyCardsCustomize />
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="edit/:cardId"
                                    element={
                                        <Suspense
                                            fallback={<SuspenseSpinner />}
                                        >
                                            <EditLayout />
                                        </Suspense>
                                    }
                                >
                                    <Route
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <DetailsNavbar />
                                            </Suspense>
                                        }
                                    >
                                        <Route
                                            element={
                                                <Suspense
                                                    fallback={
                                                        <SuspenseSpinner />
                                                    }
                                                >
                                                    <CustomizeLandingPage />
                                                </Suspense>
                                            }
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense
                                                        fallback={
                                                            <SuspenseSpinner />
                                                        }
                                                    >
                                                        <CustomizeDesign />
                                                    </Suspense>
                                                }
                                            />

                                            <Route
                                                path="contact-info"
                                                element={
                                                    <Suspense
                                                        fallback={
                                                            <SuspenseSpinner />
                                                        }
                                                    >
                                                        <CustomFormContactDetails />
                                                    </Suspense>
                                                }
                                            />

                                            <Route
                                                path="links"
                                                element={
                                                    <Suspense
                                                        fallback={
                                                            <SuspenseSpinner />
                                                        }
                                                    >
                                                        <CustomFormLinks />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="apple-wallet"
                                            element={
                                                <Suspense
                                                    fallback={
                                                        <SuspenseSpinner />
                                                    }
                                                >
                                                    <CustomizeAppleWallet />
                                                </Suspense>
                                            }
                                        />
                                    </Route>
                                </Route>
                                {userRole === 'team' &&
                                    !(
                                        (role === 'admin' && !isCardsAdmin) ||
                                        (role === 'employee' &&
                                            !isCardsEmployee)
                                    ) && (
                                        <Route path="teams-cards">
                                            <Route
                                                index
                                                element={
                                                    <Suspense
                                                        fallback={
                                                            <SuspenseSpinner />
                                                        }
                                                    >
                                                        <TeamsCards
                                                            isCustomize
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="edit/:cardId"
                                                element={
                                                    <Suspense
                                                        fallback={
                                                            <SuspenseSpinner />
                                                        }
                                                    >
                                                        <EditLayout />
                                                    </Suspense>
                                                }
                                            >
                                                <Route
                                                    element={
                                                        <Suspense
                                                            fallback={
                                                                <SuspenseSpinner />
                                                            }
                                                        >
                                                            <DetailsNavbar />
                                                        </Suspense>
                                                    }
                                                >
                                                    <Route
                                                        element={
                                                            <Suspense
                                                                fallback={
                                                                    <SuspenseSpinner />
                                                                }
                                                            >
                                                                <CustomizeLandingPage />
                                                            </Suspense>
                                                        }
                                                    >
                                                        <Route
                                                            index
                                                            element={
                                                                <Suspense
                                                                    fallback={
                                                                        <SuspenseSpinner />
                                                                    }
                                                                >
                                                                    <CustomizeDesign />
                                                                </Suspense>
                                                            }
                                                        />

                                                        <Route
                                                            path="contact-info"
                                                            element={
                                                                <Suspense
                                                                    fallback={
                                                                        <SuspenseSpinner />
                                                                    }
                                                                >
                                                                    <CustomFormContactDetails />
                                                                </Suspense>
                                                            }
                                                        />

                                                        <Route
                                                            path="links"
                                                            element={
                                                                <Suspense
                                                                    fallback={
                                                                        <SuspenseSpinner />
                                                                    }
                                                                >
                                                                    <CustomFormLinks />
                                                                </Suspense>
                                                            }
                                                        />
                                                    </Route>
                                                    <Route
                                                        path="apple-wallet"
                                                        element={
                                                            <Suspense
                                                                fallback={
                                                                    <SuspenseSpinner />
                                                                }
                                                            >
                                                                <CustomizeAppleWallet />
                                                            </Suspense>
                                                        }
                                                    />
                                                </Route>
                                            </Route>
                                        </Route>
                                    )}
                            </Route>

                            <Route
                                path="contacts"
                                element={
                                    <Suspense fallback={<SuspenseSpinner />}>
                                        <WidthObserver>
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <Contacts />
                                            </Suspense>
                                        </WidthObserver>
                                    </Suspense>
                                }
                            />
                            {/* Uncomment after Purchases build */}
                            {/* <Route path="purchases" element={ <Suspense fallback={<SuspenseSpinner />}><Purchases /></Suspense>} /> */}
                            {/* 404 page */}
                            {!isOrganizationUser ? (
                                <Route
                                    path="settings"
                                    element={
                                        <Suspense
                                            fallback={<SuspenseSpinner />}
                                        >
                                            <SettingsLayout />
                                        </Suspense>
                                    }
                                >
                                    <Route
                                        index
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <Settings />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="user-management"
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <Teams />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="roles"
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <Roles />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="pricing-plans"
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <Plan />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="integrations"
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <Integrations />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                            ) : (
                                <Route
                                    path="settings"
                                    element={
                                        <Suspense
                                            fallback={<SuspenseSpinner />}
                                        >
                                            <SettingsLayout />
                                        </Suspense>
                                    }
                                >
                                    <Route
                                        index
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <Settings />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="pricing-plans"
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <Plan />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="integrations"
                                        element={
                                            <Suspense
                                                fallback={<SuspenseSpinner />}
                                            >
                                                <Integrations />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                            )}
                        </Route>

                        <Route
                            element={
                                <Suspense fallback={<SuspenseSpinner />}>
                                    <Suspense fallback={<SuspenseSpinner />}>
                                        <OnboardingLayout />
                                    </Suspense>
                                </Suspense>
                            }
                        >
                            <Route
                                path="/subscription"
                                element={
                                    <Suspense fallback={<SuspenseSpinner />}>
                                        <Subscription />
                                    </Suspense>
                                }
                            />
                        </Route>
                        <Route
                            path="/onboarding"
                            element={
                                <Suspense fallback={<SuspenseSpinner />}>
                                    <Onboarding />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/oauth"
                            element={
                                <Suspense fallback={<SuspenseSpinner />}>
                                    <ZapierOauth />
                                </Suspense>
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <Suspense fallback={<SuspenseSpinner />}>
                                    <Page404 />
                                </Suspense>
                            }
                        />
                        <Route
                            path="not-found"
                            element={
                                <Suspense fallback={<SuspenseSpinner />}>
                                    <Page404 />
                                </Suspense>
                            }
                        />
                        {/* sing in register page */}
                    </Route>
                    <Route
                        path="/id/:cardId/"
                        element={
                            <Suspense fallback={<SuspenseSpinner />}>
                                <Suspense fallback={<SuspenseSpinner />}>
                                    <DetailsLandingPage />
                                </Suspense>
                            </Suspense>
                        }
                    />
                    <Route
                        path="custom-design-form"
                        element={
                            <Suspense fallback={<SuspenseSpinner />}>
                                <CustomDesignForm />
                            </Suspense>
                        }
                    />
                    <Route
                        element={
                            <Suspense fallback={<SuspenseSpinner />}>
                                <LoginRegisterProtectedRoute />
                            </Suspense>
                        }
                    >
                        <Route
                            path="register"
                            element={
                                <Suspense fallback={<SuspenseSpinner />}>
                                    <Register />
                                </Suspense>
                            }
                        />
                        <Route
                            path="login"
                            element={
                                <Suspense fallback={<SuspenseSpinner />}>
                                    <Login />
                                </Suspense>
                            }
                        />
                    </Route>
                </Routes>
            </UserLimitationContextProvider>
        </Suspense>
    );
}

export default App;
